import { useMemo } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { BookshelfPage } from '../bookshelf';
import { QueuePage } from '../queueNhistory/QueuePage';
import { HistoryPage } from '../queueNhistory/HistoryPage';
import { ProfilePage } from '../auth/ProfilePage';
import { StoreHomePage } from '../home';
import { ListEbook } from '../listEbook';
import { SetsPage, SetsContentListPage } from '../set/setEbook';
import { DetailPage } from '../store/DetailPage';
import { v4 as uuidv4 } from 'uuid';
import { SearchResult } from '../search';
import { convertSetType } from '../../routes/generatePath';
import { HibraryRootState } from '../../../redux/rootReducer';
import { CategoriesPage, CategoryContentPage } from '../content';
import { ViewAllContent } from '../content/viewAllContent';
import {
  ContentType,
  getLicenseType,
  LicenseType
} from '../../../interface/home';
import { isMobile, isTablet } from 'react-device-detect';
import { getDynamicLink } from '../../Utilities/dynamicLink';
import { Platform } from '../../../interface/dynamicLink';
import { TermsOfServicePage } from '../../redux/termsOfService/request';
import Logout from '../auth/Logout';
import QuestionnaireForm from '../questionnaire/questionnaire';
import LogoutSSO from '../auth/LogoutSSO';
import NotFoundPage from './NotFoundPage';
import ViewAllSponsor from '../viewAllSponsor/ViewAllSponsor';
import SponsorSetEbook from '../set/sponsorSetEbook';
import ViewAllSponsorBanner from '../viewAllSponsorBanner';
import VoteEbook from '../voteEbook';
import CatalogContents from '../voteEbook/catalogContents';
import ScrollToTop from '../../helpers/scrollToTop';
import RentableContents from '../content/RentableContent';
import OpenAppTab from '../../components/openAppPopup/OpenAppPopup';
import QrCode from '../qrCode';
import TermsOfService from '../termsOfService';

const BasePage = () => {
  const location = useLocation();
  const { authState, clientInfo, environment } = useSelector(
    (state: HibraryRootState) => ({
      authState: state.auth,
      clientInfo: state.clientInfo,
      environment: state.environment,
      request: state.fetch
    }),
    shallowEqual
  );

  const scrollToTop = (
    <img
      src="/images/goToTop.png"
      alt=""
      className={
        environment.scrollToTop && !environment.cookieTab
          ? 'gotoTop isShow'
          : 'gotoTop isClose'
      }
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    />
  );

  const storeHomePageMemoPrivate = useMemo(
    () => <StoreHomePage licenseType={LicenseType.Private} key={uuidv4()} />,
    [authState, clientInfo]
  );

  const storeHomePageMemoRent = useMemo(
    () => (
      <StoreHomePage
        licenseType={LicenseType.Rent}
        key={uuidv4()}
        contentType={ContentType.content}
      />
    ),
    [authState, clientInfo]
  );

  const storeHomePageMemoFree = useMemo(
    () => (
      <StoreHomePage
        licenseType={LicenseType.Free}
        key={uuidv4()}
        contentType={ContentType.content}
      />
    ),
    [authState, clientInfo]
  );

  const OpenAppTabMemo = useMemo(() => {
    const url = getDynamicLink(
      window.location.href,
      authState.isCuDomain ? Platform.CuElibrary : Platform.Hibrary
    );
    if ((isMobile || isTablet) && !environment.cookieTab)
      return <OpenAppTab url={url} />;
  }, [location.pathname, environment.cookieTab, authState]);

  // const CookieTabMemo = useMemo(() => {
  //     return <CookieTab />;
  // }, [request, environment.cookieTab]);

  return (
    <div className="base-page">
      {OpenAppTabMemo}
      <ScrollToTop>
        {scrollToTop}
        <Switch>
          <Route path="/question-form">
            <QuestionnaireForm />
          </Route>
          <Route
            exact
            path="/viewAllSponsor"
            render={() => <ViewAllSponsor />}
          />
          <Route
            path="/terms-of-service/:page"
            exact
            render={(props) => (
              <TermsOfService
                currentPage={props.match.params.page as TermsOfServicePage}
              />
            )}
          />

          <Route path="/" exact render={() => storeHomePageMemoRent} />
          <Route path="/free" exact render={() => storeHomePageMemoFree} />
          <Route
            path="/private"
            exact
            render={() => storeHomePageMemoPrivate}
          />
          <Route path="/listEbook" exact render={() => <ListEbook />} />
          <Route
            path="/listEbook/:type"
            exact
            render={(props) => <ListEbook type={props.match.params.type} />}
          />
          <Route
            path="/listEbook/:type/:id"
            exact
            render={(props) => (
              <ListEbook
                type={props.match.params.type}
                id={props.match.params.id}
              />
            )}
          />
          <Route
            path="/listEbook/:type/:id/:subId"
            exact
            render={(props) => (
              <ListEbook
                type={props.match.params.type}
                id={props.match.params.id}
                subId={props.match.params.subId}
              />
            )}
          />
          <Route
            path="/:contentType/all/rentableContent"
            exact
            render={(props) => (
              <RentableContents
                contentType={props.match.params.contentType as ContentType}
              />
            )}
          />
          <Route
            path="/search/:searchValue"
            render={(props) => (
              <SearchResult keyword={props.match.params.searchValue} />
            )}
          />
          <Route
            path="/:license/categories/:catId/:content/list"
            render={(props) => (
              <CategoryContentPage
                catId={props.match.params.catId}
                contentType={props.match.params.content as ContentType}
                licenseType={getLicenseType(props.match.params.license)}
              />
            )}
          />
          <Route
            path="/:license/categories/:content/all"
            render={(props) => (
              <CategoriesPage
                contentType={props.match.params.content as ContentType}
                licenseType={getLicenseType(props.match.params.license)}
              />
            )}
          />

          <Route
            path="/:license/categories/:catId/subcategories/:subcatId/:content/all"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={getLicenseType(props.match.params.license)}
                catId={props.match.params.catId}
                subcatId={props.match.params.subcatId}
              />
            )}
          />
          <Route
            path="/:license/creator/:creatorUID/:content"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={getLicenseType(props.match.params.license)}
                creatorUID={props.match.params.creatorUID}
              />
            )}
          />
          <Route
            path="/:license/writer/:writerNameOrId/:content/:creatorUID?"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={getLicenseType(props.match.params.license)}
                writerNameOrId={props.match.params.writerNameOrId}
                creatorUID={props.match.params?.creatorUID}
              />
            )}
          />
          <Route
            path="/:license/keyword/:keyword/:content"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={getLicenseType(props.match.params.license)}
                keyword={props.match.params.keyword}
              />
            )}
          />
          <Route
            path="/:license/:contentType/detail/:uid"
            render={(props) => (
              <DetailPage
                {...props}
                uid={props.match.params.uid}
                licenseType={getLicenseType(props.match.params.license)}
              />
            )}
          />
          <Route
            path="/:license/detail/:uid"
            render={(props) => (
              <DetailPage
                {...props}
                uid={props.match.params.uid}
                licenseType={getLicenseType(props.match.params.license)}
              />
            )}
          />
          <Route
            path="/rent/all/:content"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={LicenseType.Rent}
              />
            )}
          />
          <Route
            path="/free/all/:content"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={LicenseType.Free}
              />
            )}
          />
          <Route
            path="/private/all/:content"
            render={(props) => (
              <ViewAllContent
                contentType={props.match.params.content as ContentType}
                licenseType={LicenseType.Private}
              />
            )}
          />
          <Route
            path="/:license/home/:content"
            exact
            render={(props) => (
              <StoreHomePage
                licenseType={getLicenseType(props.match.params.license)}
                contentType={props.match.params.content as ContentType}
              />
            )}
          />
          {/* <Route path='/:license/creator/:creatorUID/:content' render={() => <div></div>} /> */}
          <Route
            exact
            path="/:license/sets/:setType/:setId"
            render={(props) => (
              <SetsContentListPage
                licenseType={getLicenseType(props.match.params.license)}
                setType={convertSetType(props.match.params.setType)}
                setUID={props.match.params.setId}
              />
            )}
          />
          <Route
            exact
            path="/sponsor/sets/:setUID"
            render={(props) => (
              <SponsorSetEbook setUID={props.match.params.setUID} />
            )}
          />
          <Route
            exact
            path="/viewAllSponsorBanner"
            render={() => <ViewAllSponsorBanner />}
          />
          <Route
            exact
            path="/:license/sets/:setType"
            render={(props) => (
              <SetsPage
                licenseType={getLicenseType(props.match.params.license)}
                setType={convertSetType(props.match.params.setType)}
              />
            )}
          />

          <Route path="/queue" exact render={() => <QueuePage />} />
          <Route path="/history" exact render={() => <HistoryPage />} />
          <Route path="/profile" exact render={() => <ProfilePage />} />
          <Route path="/voteEbook" exact render={() => <VoteEbook />} />
          <Route
            path="/voteEbook/:catalogUID"
            exact
            render={(props) => (
              <CatalogContents catalogUID={props.match.params.catalogUID} />
            )}
          />
          <Route path="/bookshelf" exact render={() => <BookshelfPage />} />
          {/* <Route
                        path='/bookshelfNew'
                        exact
                        render={() => <BookShelf />}
                    /> */}
          <Route path="/qrCode/:qrCodeUID" exact render={() => <QrCode />} />
          <Route path="/logout" exact render={() => <Logout />} />
          <Route path="/logout-sso" exact render={() => <LogoutSSO />} />
          <Route
            path="/special-path/close-tab"
            exact
            render={() => {
              window.close();
            }}
          />
          <Route
            path="/error/not-found"
            exact
            render={() => <NotFoundPage />}
          />
          <Route
            path="/error/not-found-organization"
            exact
            render={() => <NotFoundPage />}
          />
          <Redirect to="/error/not-found" />
        </Switch>
      </ScrollToTop>
    </div>
  );
};
export default BasePage;
