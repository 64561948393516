import React, { useEffect, useState } from 'react';
import { ClientRefactor } from '../../../../../interface';
import { useDispatch, useSelector } from 'react-redux';
import { action } from '../../../../redux/request';
import {
  actions,
  actions as AuthAction
} from '../../../../redux/auth/authRedux';
import { Modal, Space } from 'antd';
import { getDomainName } from '../../../../pages/main';
import { ComponentLoading } from '../../../../customUI/loading';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import { useIntl } from 'react-intl';
import { ConfirmPasswordModal } from '../confirmPasswrodModal/ConfirmPasswordModal';
import {
  deleteClientFromMember,
  getClientRegisteredInMember,
  refreshNewToken
} from '../../../../redux/auth/authCrud';
import dayjs from 'dayjs';
import trash from '../../../../pages/devices/trash.svg';
import SVG from 'react-inlinesvg';

const SelectLibrary = () => {
  const { confirm } = Modal;
  const pageId = 'authSelectLibraryPage';
  const dispatch = useDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<ClientRefactor[]>();
  const [redirectPath, setRedirectPath] = useState<string>();
  const [clientNameConfirm, setClientNameConfirm] = useState<ClientRefactor>();
  const { auth, clientInfo } = useSelector((state: HibraryRootState) => ({
    auth: state.auth,
    clientInfo: state.clientInfo
  }));

  useEffect(() => {
    dispatch(action.setProcess(true, pageId));
    getClientRegisteredInMember().then((res) => {
      setClients(res.data?.clients);
      dispatch(action.setProcess(false, pageId));
    });
  }, []);

  useEffect(() => {
    if (redirectPath && auth.authToken === undefined) {
      window.location.href = redirectPath;
    }
  }, [redirectPath, auth.authToken]);

  const onChangeLibrary = (client: ClientRefactor) => {
    let pathRedirect: string;
    confirm({
      content: <h4>คุณต้องการเปลี่ยนห้องสมุดหรือไม่</h4>,
      okText: 'ตกลง',
      cancelText: 'ยกเลิก',
      onOk() {
        setLoading(true);
        refreshNewToken(client.companyPrefix).then((res) => {
          const reg = new RegExp(
            `((elibrary-([A-z0-9]+))|bkk)\.${getDomainName(
              window.location.hostname
            )}`
          );

          switch (client.companyPrefix) {
            case 'bkk': {
              pathRedirect = `bkk.hibrary.me?ref=${
                auth.userInfo?.uid
              }&&authenUserToken=${encodeURIComponent(res.data?.code ?? '')}`;
              break;
            }
            case 'tat': {
              pathRedirect = `elibrary.tat.or.th?ref=${
                auth.userInfo?.uid
              }&&authenUserToken=${encodeURIComponent(res.data?.code ?? '')}`;
              break;
            }
            default:
              break;
          }

          if (window.location.origin.match(reg)) {
            const newLocation = window.location.origin.replace(
              reg,
              `elibrary-${client.companyPrefix}.${getDomainName(
                window.location.hostname
              )}`
            );
            pathRedirect = `${newLocation}?ref=${
              auth.userInfo?.uid
            }&&authenUserToken=${encodeURIComponent(res.data?.code ?? '')}`;
          } else {
            if (clientInfo.companyPrefix === 'tat') {
              pathRedirect = `https://elibrary-${
                client.companyPrefix
              }.hibrary.me?ref=${
                auth.userInfo?.uid
              }&&authenUserToken=${encodeURIComponent(res.data?.code ?? '')}`;
            } else {
              pathRedirect = `https://elibrary-${
                client.companyPrefix
              }.${getDomainName(window.location.hostname)}?ref=${
                auth.userInfo?.uid
              }&&authenUserToken=${encodeURIComponent(res.data?.code ?? '')}`;
            }
          }

          setRedirectPath(pathRedirect);
          dispatch(actions.logout());
        });
      }
    });
  };

  const onRemove = async (password: string, clientUID: string) => {
    try {
      setLoading(true);

      const res = await deleteClientFromMember(password, clientUID);
      if (res.status) {
        setClients((prev) => {
          if (prev) {
            return prev.filter((el) => el.clientUID !== clientUID);
          } else {
            return undefined;
          }
        });
        setLoading(false);
        setClientNameConfirm(undefined);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const clientLibraryRow = (client: ClientRefactor) => {
    return (
      <div className="clientItem">
        <Space size={16} onClick={() => onChangeLibrary(client)}>
          <div className="coverImage">
            <img src={client.logoImages} />
            {client.isCurrentClient && <div className="online-status" />}
          </div>
          <div>
            <div className="clientName">{client.clientName} </div>
            <div className="clientDetail">
              {intl.formatMessage(
                { id: 'AddLibrary.BorrowNo' },
                { 0: client.license }
              )}{' '}
              {intl.formatMessage(
                { id: 'AddLibrary.BookNo' },
                { 0: client.booking }
              )}
            </div>
            <div className="clientDetail">
              {intl.formatMessage(
                { id: 'AddLibrary.AccountTime' },
                {
                  0: dayjs
                    .unix(client.expireDate)
                    .format(
                      intl.locale === 'th'
                        ? 'DD/MM/YYYY HH:mm น.'
                        : 'DD/MM/YYYY HH:mm'
                    )
                }
              )}
            </div>
          </div>
        </Space>
        {!client.isCurrentClient && !auth.isSsoLogin ? (
          <div className="removeClient">
            <SVG
              src={trash}
              onClick={() => setClientNameConfirm(client)}
              width={24}
              height={24}
            />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="selectLibraryContainer">
      <h1>{intl.formatMessage({ id: 'AddLibrary.Title' })}</h1>
      <div className="clientListContainer">
        {clients?.map((el) => clientLibraryRow(el))}
        <ComponentLoading id={pageId} absolute={true} />
        <ConfirmPasswordModal
          clientNameConfirm={clientNameConfirm}
          setClientNameConfirm={setClientNameConfirm}
          onRemove={onRemove}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SelectLibrary;
