import { Themes } from './client';
import { Device } from './device';

export interface OtpPassword {
  uid?: string;
  userID?: string;
  referenceCode?: string;
  transactionDate?: number;
  expireDate?: number;
  verifyOtp?: string;
}

export interface OtpRegister extends OtpPassword {
  userID: string;
  email?: string;
}

export interface SignInSsoResponse {
  client: DataClient;
  clients: SignInSso;
  homepageUrl: string;
  userToken: string;
}

export interface SignInSsoBody {
  code?: string;
  clientInfo?: string;
  accessToken?: string;
}

export interface DataClient {
  themes: Themes;
}

export interface SignInSso {
  clientUID: string;
  clients: SignInSsoClientElement[];
  expireDate: number;
  memberType: string;
  startDate: number;
}

export interface SignInSsoClientElement {
  booking: number;
  clientName: string;
  clientUID: string;
  companyPrefix: string;
  displayName: string;
  expireDate: number;
  isActive: number;
  isCurrentClient: number;
  license: number;
  logoImages: string;
  memberType: string;
  status: number;
  subLibrary: string;
}

export interface SsoUrl {
  ssoUrl: string;
  redireactUrl: string;
}

export interface Data {
  userToken: string;
  firebaseToken: string;
  devices: Device[];
  client: Client;
}

export interface Client {
  themes: Themes;
}

export enum SendEmailForOtpType {
  ForgotPassword = 'ForgotPassword',
  RegisterByEmail = 'RegisterByEmail',
  RegisterByPhoneNumber = 'RegisterByPhoneNumber',
  ChangeEmail = 'ChangeEmail',
  ChangePassword = 'ChangePassword'
}

export enum RegisterType {
  Email = 'Email',
  PhoneNumber = 'PhoneNumber'
}

export enum AuthPageType {
  Login,
  Register,
  RegisterOTP,
  SuccessRegister,
  ForgotPassword,
  ForgotPasswordOTP,
  SuccessForgotPassword,
  ChangeEmail,
  ChangeEmailOTP,
  SuccessChangeEmail,
  ChangePassword,
  ChangePasswordOTP,
  SuccessChangePassword,
  VerifyOTP,
  NewPassword,
  Success,
  FullDevice,
  SelectLibrary,
  AddNewLibrary,
  AddNewLibraryForm,
  RegisterForm
}

export interface TatAccessToken {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  acct: number;
  acr: string;
  aio: string;
  amr: string[];
  app_displayname: string;
  appid: string;
  appidacr: string;
  family_name: string;
  given_name: string;
  idtyp: string;
  ipaddr: string;
  name: string;
  oid: string;
  onprem_sid: string;
  platf: string;
  puid: string;
  rh: string;
  scp: string;
  signin_state: string[];
  sub: string;
  tenant_region_scope: string;
  tid: string;
  unique_name: string;
  upn: string;
  uti: string;
  ver: string;
  wids: string[];
  xms_idrel: string;
  xms_st: XMSSt;
  xms_tcdt: number;
}

export interface XMSSt {
  sub: string;
}
